export interface Check {
  checkNumber: number;
  checkTotal: number;
  id: string;
  tableName: string;
  subTotal: number;
  taxTotal: number;
  orderNumber: string;
  products: Product[];
  revenueCenter: RevenueCenter;
}

export interface PaymentProvider {
  providerName: string;
  config: Record<string, any>;
}

export interface Product {
  id: string;
  name: string;
  quantity: number;
  menuPrice: number;
}

export interface RevenueCenter {
  id: string;
  name: string;
  number: number;
  zip?: string;
}

export type StatusCode = 'Pending' | 'Approved' | 'Declined' | 'Referral' | 'Error' | 'Unknown';

export interface Transaction {
  id: string;
  amount: number;
  cardPresent: boolean;
  createdAt: Date;
  currency: string;
  isCaptured: boolean;
  initResponse: Record<string, any>;
  providerName: string;
  taxAmount: number;
  tipAmount: number;
  status: StatusCode;
  type: 'cc' | 'apple-pay' | 'google-pay';
}

export interface TransactionRequest {
  amount: number;
  check: Check;
  currency?: string;
  provider: PaymentProvider;
  timezone: string;
  tip?: number;
  type?: 'apple' | 'cc' | 'google';
  user: User;
}

export interface User {
  id: string;
  number: number;
}

/**
 * Errors
 */

export class PaymentProviderError extends Error {}

export class TransactionError extends Error {}

export class AuthorizeError extends TransactionError {}

export class CaptureError extends TransactionError {}
