import { MenuItem } from './models';
import { menu as shift4 } from './shift4';

export * from './models';

export const TAX_RATE = 0.1;

export const Menus: Record<string, MenuItem[]> = {
  shift4,
};
