import { useEffect, useRef, useState } from 'react';
import { PaymentProvider } from './PaymentProvider';

const applePayEnabled = !!(window as Record<string, any>).ApplePaySession;

export const Shift4Provider: PaymentProvider = ({ transaction, onBusy, onTokenization, onError }) => {
  const [hideForm, setHideForm] = useState(false);
  const frmRef = useRef<HTMLDivElement>(null);
  const $shift4 = useRef<any>(null);

  useEffect(() => {
    const { initResponse: i4go } = transaction;

    $shift4.current = ($(frmRef) as any).i4goTrueToken({
      debug: true,
      remoteDebug: false,
      url: i4go.i4go_i4m_url,
      server: i4go.i4go_server,
      accessBlock: i4go.i4go_accessblock,
      self: document.location,
      template: 'bootstrap5-labeled',
      container: { classes: '' },
      cardNumber: { classes: 'mb-3' },
      i4goInfo: { visible: false },
      cardType: { visible: false },
      submitButton: { label: 'Pay with card' },
      cssRules: [
        "@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');",
        'body { font-family: "Poppins", sans-serif !important; min-height: auto; }',
        'label, input, button { display: block; font-family: "Poppins", sans-serif !important; }',
        '.form-control { padding: .5rem .6rem }',
        '#i4go-payment-form > div.row > div { width: 100% }',
        '#i4go_submit { width: 100%; margin: 1rem 0 0 !important; }',
        'label { margin-bottom: .25rem; font-family: "Sofia", sans-serif }',
        'div#container { max-width: unset; padding: 0; margin: 0; }',
      ],
      onWalletInit: (wallet: string, enabled: boolean, reason: string) => {
        console.debug(`wallet: ${wallet}, enabled: ${enabled}, reason: ${reason}`);
      },
      onSuccess: (_: any, data: any) => {
        console.debug(`${new Date().toISOString()} pebble:`, data);
        onBusy && onBusy(false);
        //frmRef.current?.remove();
        onTokenization && onTokenization(data);
        setHideForm(true);
      },
      onFailure: (_: any, data: any) => {
        console.error(`${new Date().toISOString()} pebble:`, data);
        onError && onError(data);
      },
    });
  }, [onBusy, onError, onTokenization, transaction]);

  return (
    <div ref={frmRef} style={{ display: hideForm ? 'none' : 'block' }}>
      <div>
        <div id="i4goFrame" style={{ width: '100%' }}></div>
      </div>

      <div>
        <p className="text-center border-bottom my-4" style={{ lineHeight: '.1rem' }}>
          <span className="bg-white px-2">or</span>
        </p>

        <div style={{ marginTop: 30 }}>
          <button className="pay-button pay-hidden apple-pay-button"></button>
          {!applePayEnabled && <button className="pay-button pay-hidden google-pay-button"></button>}
        </div>
      </div>
    </div>
  );
};
