import { PaymentProviderError } from '../models';
import { PaymentProvider, PaymentProviderProps } from './PaymentProvider';
import { Shift4Provider as shift4 } from './Shift4Provider';

const providers: Record<string, PaymentProvider> = {
  shift4,
};

export type { PaymentProviderProps };

export const usePaymentProvider = (providerName: string) => {
  const component = providers[providerName];
  if (!component) {
    throw new PaymentProviderError(`Unsupported payment provider: ${providerName}`);
  }

  return component;
};
