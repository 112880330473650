import { useCallback, useMemo } from 'react';
import { AuthorizeError, CaptureError, Transaction, TransactionError } from './models';

// TODO: change
const API_BASE_URL_DEV = process.env.REACT_APP_API_BASE_URL;

/**
 * Transaction hook to authorize and capture payment.
 * @param trans Transaction response from API.
 * @param isDev Is used for production. Will switch url.
 * @returns
 */
export const useTransaction = (trans?: Transaction, isDev?: boolean) => {
  const { authUrl, captureUrl } = useMemo(() => {
    const baseUrl = isDev ? API_BASE_URL_DEV : 'https://api.pebblepos.com';
    return {
      authUrl: `${baseUrl}/transactions/${trans?.id}/authorize`,
      captureUrl: `${baseUrl}/transactions/${trans?.id}/capture`,
    };
  }, [isDev, trans]);

  /**
   * Post authorization for transaction where `providerData` represents
   * the payload needed from integration (ex: tokenized response).
   * @param providerData Data from integration needed for authorization.
   */
  const authorize = useCallback(
    async (providerData: unknown) => {
      if (!trans) {
        throw new TransactionError('Transaction is null');
      }

      if (trans.status !== 'Pending') {
        throw new AuthorizeError(`This transaction has been authorized. Status: ${trans.status}`);
      }

      const req = { providerData };

      console.debug('>> Authorizing...');
      const res = await fetch(authUrl, { ...getHeaders(req) });

      console.debug('<< Authorization done!');
      const json = await res.json();

      return json as Transaction;
    },
    [authUrl, trans],
  );

  /**
   * Capture the authorized transaction.
   */
  const capture = useCallback(async () => {
    if (!trans) {
      throw new TransactionError('Transaction is null');
    }

    if (trans.isCaptured) {
      throw new CaptureError('This transaction has been captured');
    }

    if (trans.status !== 'Approved') {
      throw new CaptureError('This transaction must be authorized prior to capture');
    }

    console.debug('>> Capturing...');
    const res = await fetch(captureUrl, { ...getHeaders({}) });

    console.debug('<< Capture done!');
    const json = await res.json();

    return json as Transaction;
  }, [captureUrl, trans]);

  const getHeaders = (body: unknown) => ({
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  return { authorize, capture };
};
