import { MenuItem } from './models';

export const menu: MenuItem[] = [
  {
    id: '03',
    name: 'Authorization',
    description: 'Authorization',
    price: 125.9,
    tax: 0.1,
  },
  {
    id: '04',
    name: 'Auth Sale',
    description: 'Google Pay',
    price: 199.9,
    tax: 0.1,
  },
  {
    id: '05',
    name: 'Auth Wallet',
    description: 'Apple Pay',
    price: 299.9,
    tax: 0.1,
  },
  {
    id: '06',
    name: 'Timeout Approved',
    description: 'Timeout Approved',
    price: 111.21,
    tax: 0.1,
  },
  {
    id: '07',
    name: 'One-Pass Validation',
    description: 'One-Pass Validation - Invalid AVS',
    price: 0.9,
    tax: 0.1,
  },
  {
    id: '08',
    name: 'One-Pass Validation',
    description: 'One-Pass Validation - Invalid CSC',
    price: 4.9,
    tax: 0.1,
  },
  {
    id: '09',
    name: 'Refund',
    description: 'Refund via Token',
    price: 199.9,
    tax: 0.1,
  },
  {
    id: '10',
    name: 'Decline',
    description: 'Decline Response',
    price: 1199999.9,
    tax: 0.1,
  },
  {
    id: '11',
    name: 'Referral',
    description: 'Refund via Token',
    price: 999998,
    tax: 0.01,
  },
  {
    id: '12',
    name: 'Host Error',
    description: 'Host Error',
    price: 32201.9,
    tax: 0.1,
  },
  {
    id: '13',
    name: 'Timeout',
    description: 'Timeout Referral',
    price: 511.21,
    tax: 0.1,
  },
  {
    id: '14',
    name: 'Timeout',
    description: 'Timeout Decline',
    price: 1111.21,
    tax: 0.1,
  },
  {
    id: '15',
    name: 'Timeout',
    description: 'Timeout Error',
    price: 112.21,
    tax: 0.1,
  },
  {
    id: '16',
    name: 'Timeout',
    description: 'Timeout Blank',
    price: 112.55,
    tax: 0.1,
  },
  {
    id: '17',
    name: 'Two Pass Validation',
    description: 'Two Pass Validation',
    price: 104.9,
    tax: 0.1,
  },
];
