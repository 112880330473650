import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Checkout, Ordering, PaymentProviderSelect } from './routes';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Ordering />,
      children: [
        {
          path: 'checkout',
          element: <Checkout />,
        },
      ],
    },
    {
      path: 'providers',
      element: <PaymentProviderSelect />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
