import { FC } from 'react';
import { Transaction } from './models';
import { useTransaction } from './useTransaction';
import { usePaymentProvider } from './providers';

export type { Transaction };

/**
 * Payment properties for payment component.
 */
export interface PaymentProps {
  /**
   * Is used for dev. Default: false.
   */
  isDev?: boolean;

  /**
   * Initialized transaction from API.
   */
  transaction: Transaction;

  /**
   * On completed authorization callback. This does not mean authorization
   * was approved. Check the `status` property for approval status.
   * @param transaction Transaction response from completed authorization.
   */
  onAuthorized?: (transaction: Transaction) => void | Promise<void>;

  /**
   * Callback to indicate the component is busy.
   * @param isBusy Is busy.
   */
  onBusy?: (isBusy: boolean) => void | Promise<void>;

  onError?: (error: Error) => void | Promise<void>;
}

/**
 * Payment component for authorizing payment.
 */
export const Payment: FC<PaymentProps> = ({ transaction, onAuthorized, onBusy, onError, isDev }) => {
  const { authorize } = useTransaction(transaction, isDev);
  const ProviderComponent = usePaymentProvider(transaction.providerName);

  const authorizePayment = async (providerData: unknown) => {
    try {
      onBusy && onBusy(true);
      const authorizedTrans = await authorize(providerData);
      onAuthorized && onAuthorized(authorizedTrans);
    } catch (err: unknown) {
      onError && onError(err as Error);
    } finally {
      onBusy && onBusy(false);
    }
  };

  return (
    <ProviderComponent
      transaction={transaction}
      onTokenization={authorizePayment}
      onBusy={(isBusy) => onBusy && onBusy(isBusy)}
      onError={(err) => onError && onError(err)}
    />
  );
};
