export const getItem = <T>(key: string): T | undefined => {
  const json = localStorage.getItem(key);
  if (!json) {
    return undefined;
  }

  return JSON.parse(json) as T;
};

export const setItem = (key: string, obj: unknown) => {
  const json = JSON.stringify(obj);
  localStorage.setItem(key, json);
};
