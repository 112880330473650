import { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import { Payment, Transaction } from '../payments';
import { useOrder } from '../common/providers';
import { v4 as uuid } from 'uuid';
import { API_BASE_URL } from '../constants';

export const Checkout = () => {
  const [loading, setLoading] = useState(false);
  const [trans, setTrans] = useState<Transaction | undefined>();
  const { order, setTransaction: addPayment } = useOrder();
  const navigate = useNavigate();

  const handleAuthorized = (transaction: Transaction) => {
    console.log('auth:', transaction);
    if (transaction.status !== 'Approved') {
      alert(`Error: ${transaction.status}`);
    } else {
      setTrans(transaction);
      addPayment(transaction);
    }

    navigate('/');
  };

  useEffect(() => {
    // This is typically done on the server side
    // in production apps
    const createTrans = async () => {
      try {
        const payload = {
          amount: order.total,
          timezone: 'America/Los_Angeles',
          user: {
            id: uuid(),
            number: Date.now(),
          },
          provider: {
            providerName: 'shift4',
            config: {
              authToken: '91B25EC4-D6C5-9ED0-1A7B209FD8AF6CC9',
            },
          },
          check: {
            checkNumber: 1,
            checkTotal: order.total,
            id: uuid(),
            tableName: `tbl-${uuid()}`,
            subTotal: order.subtotal,
            taxTotal: order.tax,
            orderNumber: Date.now(),
            products: order.items.map((item) => ({
              id: item.id,
              name: item.name,
              quantity: 1,
              menuPrice: item.price,
            })),
            revenueCenter: {
              id: uuid(),
              name: "Kevin's Kafe",
              number: Date.now(),
              zip: '89148',
            },
          },
        };

        const res = await fetch(`${API_BASE_URL}/transactions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        const response = await res.json();
        console.debug('response:', response);

        setTrans(response);
      } catch (err) {
        console.error(err);
      }
    };

    createTrans();
  }, [order]);

  if (!order.items.length) {
    return <Navigate to="/" />;
  }

  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Checkout</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          {(!trans || loading) && (
            <div className="text-center">
              <Spinner variant="primary" animation="border" />
            </div>
          )}

          {trans && (
            <Payment
              isDev
              transaction={trans}
              onAuthorized={handleAuthorized}
              onBusy={setLoading}
              onError={(err) => alert(err.message)}
            />
          )}
        </div>

        <div className="d-grid">
          <Button variant="light" onClick={() => navigate('/')}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
