import * as Cache from '../Cache';
import { v4 as uuid } from 'uuid';
import { FC, PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';
import { MenuItem } from '../../data';
import { Transaction } from '../../payments';

const CACHE_KEY_PROVIDER_TYPE = 'providerType';

export interface Order {
  number: string;
  items: MenuItem[];
  subtotal: number;
  tax: number;
  total: number;
}

export enum ProviderType {
  Shift4 = 'shift4',
}

export interface OrderState {
  order: Order;
  providerType?: ProviderType;
  transaction?: Transaction;
  addItem: (item: MenuItem) => void;
  resetOrder: () => void;
  setProviderType: (providerType: ProviderType) => void;
  setTransaction: (trans: Transaction) => void;
}

const initialOrder: Order = {
  number: uuid().substring(0, 8),
  items: [],
  subtotal: 0,
  tax: 0,
  total: 0,
};

const initial: OrderState = {
  order: initialOrder,
  addItem: () => {},
  resetOrder: () => {},
  setProviderType: () => {},
  setTransaction: () => {},
};

export const OrderContext = createContext<OrderState>(initial);

export const OrderProvider: FC<PropsWithChildren> = ({ children }) => {
  const [order, setOrder] = useState<Order>(initialOrder);
  const [trans, setTrans] = useState<Transaction | undefined>();
  const [providerType, setProviderType] = useState<ProviderType | undefined>(Cache.getItem(CACHE_KEY_PROVIDER_TYPE));

  const addItem = useCallback(
    (item: MenuItem) => {
      console.log(item);
      const itemTax = item.tax;
      const itemTotal = +(item.price + itemTax).toFixed(2);

      setOrder({
        ...order,
        items: order.items.slice().concat(item),
        subtotal: order.subtotal + item.price,
        tax: order.tax + itemTax,
        total: order.total + itemTotal,
      });
    },
    [order],
  );

  const resetOrder = useCallback(() => {
    setTrans(undefined);
    setOrder({
      ...initialOrder,
      number: uuid().substring(0, 8),
    });
  }, []);

  const setProvider = (providerType: ProviderType | undefined) => {
    Cache.setItem(CACHE_KEY_PROVIDER_TYPE, providerType);
    setProviderType(providerType);
  };

  return (
    <OrderContext.Provider
      value={{
        order,
        addItem,
        providerType,
        resetOrder,
        setProviderType: setProvider,
        setTransaction: setTrans,
        transaction: trans,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = () => useContext(OrderContext);
