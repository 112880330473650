import { Button, ListGroup, Modal } from 'react-bootstrap';
import { ProviderType, useOrder } from '../common/providers';
import { useNavigate } from 'react-router-dom';

const keys = Object.keys(ProviderType);
const values = Object.values(ProviderType);

export const PaymentProviderSelect = () => {
  const { providerType, setProviderType } = useOrder();
  const navigate = useNavigate();

  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Select Payment Provider</Modal.Title>
      </Modal.Header>

      <ListGroup variant="flush">
        {keys.map((type, index) => (
          <ListGroup.Item
            action
            key={type}
            className="py-3"
            active={providerType === values[index]}
            onClick={() => setProviderType(values[index])}
          >
            {type}
          </ListGroup.Item>
        ))}
      </ListGroup>

      <Modal.Footer>
        <Button disabled={!providerType} onClick={() => navigate('/')}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
