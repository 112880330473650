import { useMemo, useState } from 'react';
import { Button, Card, Col, ListGroup, Row, Spinner } from 'react-bootstrap';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useOrder } from '../common/providers';
import { Menus } from '../data';
import { toCurrency } from '../common/helpers';
import { useTransaction } from '../payments';

export const Ordering = () => {
  const [working, setWorking] = useState(false);
  const navigate = useNavigate();
  const { addItem, order, providerType, resetOrder, transaction } = useOrder();
  const { capture } = useTransaction(transaction, true);

  const menu = useMemo(() => Menus[providerType || ''], [providerType]);

  const handleCapture = async () => {
    try {
      setWorking(true);
      await capture();
      resetOrder();
    } catch (err: unknown) {
      alert(`Error: ${(err as Error).message}`);
    } finally {
      setWorking(false);
    }
  };

  if (!providerType) {
    return <Navigate to="/providers" />;
  }

  return (
    <div className="p-3">
      <Row>
        <Col md={9}>
          <Row className="g-3">
            {menu.map((item) => (
              <Col md={3} key={item.id}>
                <Card>
                  <Card.Body>
                    <Card.Title>{item.name}</Card.Title>
                    <p className="text-muted">
                      {item.id} - {item.description}
                    </p>
                    <div className="text-muted">{toCurrency(item.price)}</div>
                  </Card.Body>
                  <Card.Footer>
                    <Button variant="outline-primary" onClick={() => addItem(item)}>
                      Add
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>

        <Col md={3}>
          <Card>
            <Card.Header>
              <Card.Title className="my-2 d-flex justify-content-between align-items-center">
                <div>
                  <div>
                    Check <small className="text-muted">{order.number}</small>
                  </div>
                  <p className="mb-0 fs- text-muted">{providerType}</p>
                </div>

                <Button variant="outline-danger" size="sm" onClick={resetOrder}>
                  Reset
                </Button>
              </Card.Title>
            </Card.Header>

            <ListGroup variant="flush">
              {order.items.map((item, index) => (
                <ListGroup.Item key={`c-${index}`} className="d-flex justify-content-between">
                  <span>{item.name}</span>
                  <span>{toCurrency(item.price)}</span>
                </ListGroup.Item>
              ))}

              {!order.items.length && <em className="p-2 d-block text-muted text-center">No Items</em>}
            </ListGroup>

            <ListGroup variant="flush">
              <ListGroup.Item className="d-flex justify-content-between fw-bold">
                <span>Subtotal</span>
                <span>{toCurrency(order.subtotal)}</span>
              </ListGroup.Item>

              <ListGroup.Item className="d-flex justify-content-between fw-bold">
                <span>Tax</span>
                <span>{toCurrency(order.tax)}</span>
              </ListGroup.Item>

              <ListGroup.Item
                className={`d-flex justify-content-between fw-bold fs-5 ${transaction?.status === 'Approved' ? 'text-success' : ''}`}
              >
                <span>Balance</span>
                <span>{toCurrency(transaction?.status === 'Approved' ? 0 : order.total)}</span>
              </ListGroup.Item>

              <ListGroup.Item className="d-grid">
                {transaction?.status !== 'Approved' && (
                  <Button disabled={!order.items.length} onClick={() => navigate('checkout')}>
                    Checkout
                  </Button>
                )}

                {transaction?.status === 'Approved' && (
                  <Button variant="success" onClick={handleCapture}>
                    {!working && 'Close Order'}
                    {working && <Spinner animation="border" size="sm" />}
                  </Button>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>

      <Outlet />
    </div>
  );
};
